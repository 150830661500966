let zh = {
    translations: {
        "component": "零件",
        "add": "加",
        "document_type": "文件类型",
        "select_theme_color": "选择主题颜色",
        "company_logo": "公司标志",
        "choose_file": "选择文件名",
        "your_company_name": "您的公司名称",
        "company_address": "公司地址",
        "currency": "货币",
        "no": "没有",
        "terms_&_condition": "条款及细则",
        "notes": "笔记",
        "date_format": "日期格式",
        "line_items": "订单项",
        "item_name": "项目名称",
        "bill_to": "记账到",
        "ship_to": "运送到",
        "valid_till": "有效期至",
        "due_date": "截止日期",
        "date": " 日期",
        "item_description": "商品描述",
        "discount": "折扣",
        "tax": "税",
        "delete": "删除",
        "add_new_item": "新增项目",
        "signature": "签名",
        "show_discount": "显示折扣",
        "show_tax": "显示税",
        "company_name": "公司名",
        "company_billing_address": "公司帐单地址",
        "billing_address_placeholder_text_value": "客户名称\n您的客户帐单地址",
        "shipping_address_placeholder_text_value": "客户名称\n您的客户的收货地址",
        "terms_and_condition_placeholder_text": "在30天内付款",
        "notes_placeholder_text": "请及时支付余额",
        "hide": "隐藏",
        "show": "节目",
        "estimate": "估计",
        "quotation": "报价单",
        "invoice": "发票",
        "purchase_order": "采购订单",
        "bills": "账单",
        "total": "总",
        "number": "数",

        "edit": "编辑",
        "products": "产品展示",
        "description": "描述",
        "qty": "数量",
        "unit_price": "单价",
        "unit price": "单价",
        "price": "价钱",
        "amount": "量",
        "subtotal": "小计",

        "choose_a_template": "选择模板",
        "go_to_deskera": "转到桌面",
        "print_this_document": "列印这份文件",
        "send_email_to_clients": "发送电子邮件给客户",

        "document_generator": "文件产生器",
        "please_wait": "请耐心等待...",
        "download_as_pdf": "下载为（.pdf）",
        "download": "下载",
        "print": "打印",
        "share": "分享",
        "try_deskera": "试试Deskera",
        "copy_link": "复制链接",
        "share_on_the_web": "在网路上分享",
        "anyone_with_the_below_link_can_view": "拥有以下链接的任何人都可以查看",

        "send_your": "发送你的",
        "quickly": "很快",
        "please_sign_up_with_deskera_to_send_your": "请注册Deskera发送您的",
        "dont_worry_your": "别担心，你的",
        "will_still_be_here": "仍然会在这里。",
        "sign_up_for_free": "免费注册",
        "already_a_user_Sign_in": "已经是用户？登入",

        "share_on_mail": "通过电子邮件共享",
        "to": "至",
        "subject": "学科",
        "enter_recipient_email_address": "输入收件人的电子邮件地址",
        "enter_subject_here": "在此输入主题",
        "send": "发送",
        "cancel": "取消",
        "edit_table": "编辑表格",
        "save": "保存",
        "buyer": "买方",
        "seller": "卖方",
        "powered_by_deskera": "由Deskera提供支持",
        "details": "细节",
        "other_comments": "其他评论",
        "bill": "法案",
        "order": "订购",

        "email": "电子邮件",

        "items": "物品",
        "services": "服务",
        "hours": "小时",
        "rate": "率",
        "other": "其他",
        "sales_tax": "销售税",
        "tax_rate": "税率",
        "sales tax": "销售税",
        "tax rate": "税率",

        "num": " 號碼",
        "template_update_failure_message": "无法更新设置。 请再试一次！",
        "template_save_failure_message": "无法保存设置。 请再试一次！",
        "template_update_success_message": "模板保存成功",
        "template_save_success_message": "模板保存成功",
        "additional_information": "附加信息",
        "other_options": "其他选择",

        "image": "图片",
        "text": "文本",
        "watermark": "水印",
        "banner": "旗帜",
        "custom_field": "自定义字段",
        "component_list": "组件清单",

        "email_address": "电子邮件地址",
        "email_address_1": "電子郵件地址",
        "is_not_valid": "无效。",

        "deskera_suites": "德斯凯拉套房酒店",
        "deskera_books": "桌面书籍",
        "deskera_people": "德斯凯拉人",
        "deskera_sales": "Deskera销售",
        "try_now": "现在试试",

        "text_color": "文字颜色",
        "image_ratio": "图像比例 (%)",
        "text_size": "字体大小",
        "opacity": "不透明度",
        "font_style": "字体样式",
        "font_weight": "字体粗细",
        "text_alignment": "文字对齐",
        "image_alignment": "图像对齐",
        "image_preview": "图片预览",
        "preview_banner": "预览横幅",
        "banner_selection": "标语选择",
        "duplicate": "重复",
        "settings": "设定值",
        "expiry": "过期",
        "batch_no": "批号",

        "saving": "保存...",
        "save_template": "保存模板",

        "name": "名称",

        "stock_items": "库存物品",

        "sending": "正在发送...",
        "set_as_default": "默认设置",

        "no_email_subject": "找不到自定义主题！ 请输入您的自定义主题，然后按保存模板",

        "expense": "费用",
        "deposit": "存款",
        "credit_note": "信用票据",
        "debit_note": "借方通知单",
        "accounts": "帐户",
        "customer_address_placeholder_text_value": "客户名称\n客户地址",
        "account_name_placeholder_text_value": "帐户代码\n帐户名称",
        "payment_to": "付款人",
        "from_account": "来自帐户",
        "payment_from": "付款人",
        "to_account": "至帐户",
        "payment_date": "付款日期",
        "document_date": "文档日期",
        "account_name": "帐户名",
        "account_description": "帐户说明",
        "tax_amount": "税额",
        "payment_amount": "付款金额",
        "line_amount": "行号额",
        "total_tax_amount": "总税额",
        "contact": "联系人",
        "driver_name": "驾驶员姓名",
        "transporter": "运输商",
        "vehicle_no": "车牌号码",
        "lr_no": "LR No.",
        "contact_address_placeholder_text_value": "联系人姓名\n联系人地址",

        "printing": "印刷..",
        "downloading": "正在下载..",

        "incorrect_format": "格式错误！",
        "few_formats_allowed": "仅允许jpg，jpeg，png文件",
        "image_missing": "图片丢失！",
        "data_missing": "缺失数据！",
        "select_image_to_proceed": "请选择图片以继续",
        "no_data_to_export": "没有要导出的数据",

        "session_expired_title": "会话已过期！",
        "session_expired_message": "您的会话已过期。 请重新登录。",
        "login": "登录",
        "generate_link_to_share": "获取链接",
        "burn_after_read": "阅读后刻录",
        "generate_link": "产生",
        "no_never": "绝不",
        "1_min": "1分钟",
        "1_hr": "1小时",
        "1_day": "1天",
        "1_year": "1年",

        "document_expired_title": "文件已过期！",
        "document_expired_message": "该文档已过期。 请联系发件人",

        "loading": "载入中...",
        "reference_date": "参考日期",
        "cheque_number": "支票号",
        "deposit_to": "存款至",

        "password": "密码",
        "password_empty_title": "密码为空！",
        "password_empty_message": "密码不能为空。 请输入密码，然后重试！",
        "document_not_found_title": "找不到文件！",
        "document_incorrect_password_title": "密码错误！",
        "document_incorrect_password_message": "请输入正确的密码，然后重试。",
        "proceed": "继续",
        "document_enter_password_title": "受密码保护的文档。",
        "document_enter_password_message": "请输入密码。",

        "preparing_document_email": "正在准备要发送电子邮件的文档…",
        "preparing_document_print": "正在准备要打印的文件…",
        "loading_the_page": "载入页面",
        "sender": "发件人",
        "reply_to": "回复至",
        "enter_sender_name": "输入发件人名称",
        "enter_reply_to_email": "输入回复电子邮件",
        "send_a_copy_to_myself_at": "在以下位置将副本发送给自己",

        "payment": "支付",
        "paper_format": "纸张格式",
        "please_provide_paper_format": "请提供文件格式",
        "paper_format_popup_note": "您仍然可以从设置面板更新纸张格式",
        "item_number": "产品编号",
        "item_code": "物品代",
        "received_from": "接收表格",
        "payment_details": "付款明细",
        "sn_number": "序列号",
        "document_details": "文件详细",
        "template_saved": "模板已保存!",
        "you_design_template_has_been_saved": "模板已保存，您可以关闭此窗口并返回到Books应用程序。",
        "you_design_template_has_been_saved_people": "模板已保存，您可以关闭此窗口并返回People应用程序。",
        "go_to_books": "转到Book",
        "item_tax": "产品税",
        "item_discount": "产品折扣",
        "uom": "计量单位",
        "unitPrice": "单价",
        "code": "码",
        "quantity": '数量',
        "machine_name": "机器名称",
        "technician_name": "技术员姓名",
        "start_date": "开始日期",
        "schedule_id": "计划ID",
        "end_date": "结束日期",
        "please_select_custom_field": "请选择自定义字段。",

        "preview_pdf": "PDF预览",

        "template_name": "模板名称",
        "please_enter_template_name_to_save": "请输入要保存的模板名称。",
        "custom_template": "自定义模板",
        "add_new_template": "添加新的自定义模板",
        "vendor_name": "供应商名称",

        "decimal_point": "小数点",
        "footer": "页脚",

        "save_as": "另存为",
        "save_as_new_template": "另存为新模板",
        "e_invoice_section": "电子发票部分",
        "amount_before_tax": "税前金额",

        "order_no": "订单号",
        "PURCHASE_INVOICE": '账单',
        "PURCHASE_ORDER": '订购',
        "SALES_INVOICE": "发票",
        "QUOTATION": "报价单",
        "fulfillment": "应验",
        "ship_from": "发货地",
        "company_address_placeholder_text_value": "公司名称\n您公司的地址",
        "goods_receipt": "货物收据",
        "required_qty": "所需数量",
        "received_qty": "收到的数量",
        "committed_qty": "承诺数量",
        "warehouse_code": "仓库代码",
        "warehouse_name": "仓库名称",
        "company_warehouse_name": "公司/仓库名称",
        "payment_footer": "支付",
        "make_payment": "付款",
        "receive_payment": "收款",
        "deposit_advpayment": "预付款",
        "expense_prepayment": "预付款",

        "delete_template": "删除模板",
        "delete_template_message": "您确定要删除模板吗？一旦删除，模板将无法恢复。",
        "delete_template_ok_label": "是的。删除它。",
        "delete_template_success_message": "模板删除成功。",
        "delete_template_failure_message": "无法删除设置。请重试！",
        "before_tax": "税前",
        "outstanding": "未偿金额",

        "signature_section": "签名部分",
        "prepared_by": "准备者",
        "approved_by": "批准人",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "串行/批次",
        "amount_in_words": "金额文字",
        "name_with_description": "名称",

        "invoice_number": "发票编号",
        "bill_number": "账单编号",
        "total_invoice_amount": "总发票金额",
        "total_bill_amount": "总账单金额",
        "payment_made": "已付款",
        "current_due_amount": "当前到期金额",
        "current_balance": "当前余额",
        "payment_medium": "付款方式",
        "cash": "现金",
        "prepayment": "预付款",
        "payment_label": "付款",
        "receipt_label": "收款",
        "total_row": "汇总行",

        "journal_entry": "期刊条目",
        "journal_entry_number": "期刊条目 编号",
        "account_code": "账户代码",
        "credit": "信用",
        "debit": "借记",
        "GOOD_RECEIPT": "货物收据",
        "MAKE_PAYMENT": "支付",
        "RECEIVE_PAYMENT": "支付",
        "CREDIT_NOTE": "信用票据",
        "DEBIT_NOTE": "借方通知单",
        "FULFILLMENT": "应验",
        "SALES_RETURN": "销售退货",
        "PURCHASE_RETURN": "购买退货",
        "SALES_CONTRA": "销售合同",
        "PURCHASE_CONTRA": "购买合同",
        "BANK_TRANSFER": "银行转账",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "调整",
        "memo": "备忘录",

        "pick_list": "提货单",
        "pack_list": "包装清单",
        "ship_list": "运输标签",
        "invoice_or_quotation": "发票/报价单",
        "product_code": "产品代码",
        "product_name": "产品名称",
        "barcode": "条码",
        "quantity_to_pick": "数量选择",
        "quantity_required_for_order": "订单所需数量",
        "picked": "精选",
        "item_no": "编号。",
        "unit": "单元",
        "packed_quantity": "包装数量",
        "warehouse_address": "仓库地址",
        "delivery": "送货",
        "carrier": "载体",
        "number_of_carton": "纸箱数量",
        "weight": "重量",
        "tracking_number": "追踪号码",
        "package": "包裹",
        "order_number": "订单号",

        "custom_field_is_missing_title": "自定义字段丢失",
        "custom_field_is_missing_message": "此文档中缺少自定义字段。 请从 Book 添加自定义字段或尝试使用其他文档。",

        "repeatable_header": "重复表头",
        "margin": "余量",
        "default": "默认",
        "none": "无",
        "minimum": "最低",
        "large": "大的",

        "receive_by": "接收截止日期",
        "ship_by": "按日期发货",
        "additional_date": "附加日期",

        "header_footer_section": "页眉页脚部分",
        "header": "标题",
        "date_only": "仅限日期",
        "title_only": "仅标题",
        "page_count_only": "仅页数",
        "date_title": "日期和标题",
        "date_page_count": "日期和页数",
        "title_page_count": "标题和页数",
        "date_title_space": "带空格的日期和标题",
        "date_page_count_space": "带空格的日期和页数",
        "title_page_count_space": "标题和页数与空格",
        "date_title_page_count": "日期、标题、页数",
        "custom": "风俗",
        "left": "左边",
        "center": "中心",
        "right": "右边",
        "title": "标题",
        "page_count": "页数",
        "current_page_count": "当前页数",
        "total_page_count": "总页数",
        "custom_text": "自定义文本",
        "page": "页",

        "company_phone_number": "公司电话",
        "company_gstin": "公司 GSTIN",
        "company_state_name_and_code": "公司状态名称和代码",
        "payment_terms": "付款条件",
        "customer_phone_number": "客户电话号码",
        "customer_gstin": "客户 GSTIN",
        "customer_state_name_and_code": "客户状态名称和代码",
        "print_only_message": "这是计算机生成的",
        "hsn_or_sac": "HSN/SAC",
        "taxable_value": "应税价值",
        "integrated_tax": "综合税",
        "central_tax": "中央税",
        "state_tax": "州税",
        "cess": "消费电子展",
        "state_name": "州名",
        "state_code": "码",
        "label_state_code": "州代碼",

        "address_format": "地址格式",
        "address_1": "地址1",
        "address_2": "地址2",
        "city": "城市",
        "state": "状态",
        "postal_code": "邮政编码",
        "country": "国家",
        "is_apply_address_format_to_other_addresses": "将当前地址格式应用于所有地址。",
        "product_grouping": "产品分组",
        "product_group": "产品组",
        "cheque": "支票",
        "bank_transfer": "银行转账",
        "card": "卡",

        "payslip": "工资单",
        "earning_statement": "收益表",
        "pay_period": "薪水期",
        "pay_date": "发薪日",
        "ssn": "社会保障号",
        "taxable_marital_status": "应税婚姻状况",
        "exemptions": "豁免",
        "date_of_joining": "入职日期",
        "pf_a_c_NUMBER": "PF A/C号码",
        "esi_number": "ESI编号",
        "uan_number": "UAN号码",
        "employee_id": "員工ID",
        "designation": "指定",
        "employee_net_pay": "员工净工资",
        "employee_earnings": "员工收入",
        "allowances": "津贴",
        "deductions": "扣除额",
        "employer_contribution": "雇主的贡献",
        "employer_cpf": "雇主的公积金",
        "net_salary_paid": "已付净薪金",
        "employee_taxes_withhold": "雇员预扣税",
        "employer_taxes": "雇主税",
        "employee_deductions": "员工扣除",
        "summary": "概括",
        "basic_salary": "基础工资",
        "net_pay": "净工资",
        "additional_earnings": "额外收入",
        "gross_earnings": "总收益",
        "duration": "期间",
        "current": "当前的",
        "year_to_date": "今年迄今为止",
        "type": "类型",
        "pre_tax_deductions_contributions": "税前扣除/供款",
        "taxes": "税收",
        "post_tax_deductions_contributions": "税后扣除/供款",
        "net_pay_contributions": "净工资/贡献",
        "check_amount": "支票金额",
        "payslip_auto_message": "这是系统生成的工资单，不需要盖章和签名",
        "employee_address": "雇员地址",
        "field_configuration": "领域组态",
        "table_configuration": "表格组态",
        "employee_name_address": "員工姓名和地址",
        "employee_bank_acc_number": "员工的银行帐号",
        "header_title": "標題",
        "product_custom_field": "產品自定義字段",
        "quantity_and_uom": "數量和計量單位",
        "includes_product_custom_field": "包括產品自定義字段",
        "combines_product_custom_field": "結合產品自定義字段",
        "serial_number": "序列号",
        "batch_number": "批号",
        "manufacturing_date": "生产日期",
        "expiry_date": "到期日",
        "override_theme_color": "覆盖主题颜色",
        "override_global_color": "覆盖全局颜色",
        "contact_number": "电话号码",
        "contact_code": "联系代码",
        "blank": "空白",
        "pay_to": "支付给",
        "payroll_check": "工資單檢查",
        "pay_to_the": "支付給",
        "order_of": "順序",
        "dollars": "美元",
        "payroll_period": "工資單期間",
        "repeatable_header_footer": "可重复的页眉页脚",
        "repeatable_footer": "可重复的页脚",
        "includes_hsn_or_sac_code": "包括 HSN/SAC 代码",
        "eway_bill_no": "Eway 賬單號",
        "eway_bill_date": "Eway 賬單日期",
        "place_of_supply": '供应地',
        "destination_of_supply": '供应目的地',
        "source_of_supply": '供应来源',
        "subTable_configuration": "分表配置",
        "additional_table_configuration": "附加表配置",
        "select_new_type": "选择新类型",
        "qr_code": "二维码",
        "qr_string": "二维码字符串",
        "generated_qr": "生成的二维码",
        "qr_code_with_text": "带有文本的 QR 码",
        "image_with_text": "带文字的图像",
        "qr_information": "二維碼信息",
        "additional_info": "附加信息",
        "global_discount": "全球折扣",
        "pre_tax": "税前",
        "post_tax": "税后",
        "includes_uom_schema": "包括 UOM 架构",
        "table": "桌子",
        "font": "字体",
        "table_row_height": "表格行高",
        "padding_horizontal": "水平填充",
        "padding_vertical": "垂直填充",
        "original": "原來的",
        "original_for_recipient": "收件人原件",
        "duplicate_supply_of_goods": "重複（貨物供應）",
        "duplicate_for_transporter": "為運輸機複製",
        "duplicate_supply_of_service": "重複（提供服務）",
        "duplicate_for_supplier": "供應商副本",
        "triplicate": "一式三份",
        "triplicate_for_supplier": "供應商一式三份",
        "position": "位置",
        "middle": "中間",
        "sample_watermark": "樣本水印",
        "extra_copy": "額外副本",
        "quadruplicate": "一式四份",
        "MAX_IMG_UPLOAD_ALERT": "图片大小不能大于 500KB",
        "system_default": "系统默认",
        "qr_resize": "二维码调整大小",
        "top": "顶部",
        "bottom": "底部",
        "click_here_to_pay": "点击这里付款",
        "generated_message": "产生的讯息",
        "sales_order": "销售订单",
        "help": "帮助",
        "includes_account_custom_field": "包括自定义字段",
        "combines_account_custom_field": "结合自定义字段",
        "account_custom_field": "帐户自定义字段",
        "selection": "选择",
        "alias_name_address": "别名名称和地址",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "付费日",
        "unpaid_days": "无薪日",
        "payslip_overtime": "随着时间的推移",
        "payslip_LOP": "损失薪水",
        "outstanding_balance": "未结余额",
        "outstanding_balance_includes_current": "未结余额（包括当前）",
        "top_section_above_table": "表上方的顶部",
        "is_group_additional_charges_tax": "是否团体附加费税",
        "supplier_invoice_no": "供应商发票编号",
        "default_template": "默认模板",
        "contact_name": "联系人姓名",
        "current_date": "当前的日期",
        "e_way_bill_summary": "Eway 账单摘要",
        "e_way_bill_detail": "Eway 账单明细",
        "table_column_alignment": "表列对齐",
        "table_column_vertical_alignment": "垂直对齐",
        "earnings_configuration": "收益配置",
        "label_empty_field_message": "字段标签不能为空，请在其中输入一些值以继续",
        "product_table": "产品表",
        "rounding_off": "四舍五入",
        "work_order": "工作订单",
        "order_prep_completed": "订单准备完成",
        "barcode_settings": "条形码设置",
        "barcode_resize": "条形码缩放",
        "comments": "评论",
        "delivery_date": "交货日期",
        "estimated_time": "预计时间",
        "actual_quantity": "实际数量",
        "manufacture_quantity": "生产数量",
        "parent_work_order": "上级工作订单",
        "stock_transfer": "库存调拨",
        "source": "源",
        "destination": "目的地",
        "source_address": "源地址",
        "destination_address": "目的地地址",
        "transfer_date": "调拨日期",
        "verify_date": "验证日期",
        "reason": "原因",
        "warehouse": "仓库",
        "stock_adjustment": "库存调整",
        "source_warehouse_name": "源仓库名称",
        "source_warehouse_code": "源仓库代码",
        "destination_warehouse_name": "目标仓库名称",
        "destination_warehouse_code": "目标仓库代码",
        "product": "产品",
        "show_product": "显示产品",
        "hide_product_with_substitute": "隐藏带有替代品的产品",
        "substitute": "替代品",
        "show_all_substitute": "显示所有替代品",
        "show_non_zero_substitute": "显示已分配的替代品",
        "hide_substitute": "隐藏替代品",
        "combines_line_item_global_discount": "合并行项目全局折扣",
        "unit_price_before_discount": "折扣前单价",
        "unit_price_after_discount": "折扣后单价",
        "discount_per_unit": "每单位折扣",
        "show_all": "显示全部",
        "percentage": "百分比",
        "cogs": "銷售成本",
        "na": "不適用",
        "con_number": "客户订单号",
        "document_label": "标签",
        "expected_delivery_date": "预计交货日期",
        "transaction_type": "交易类型",
        "transaction_no": "交易编号",
        "status": "状态",
        "qc_document": "质量控制文件",
        "linked_po_so": "关联的采购订单/销售订单",
        "approver": "批准人",
        "sample_size": "样本大小",
        "number_of_qty_passed": "通过的数量",
        "number_of_qty_rejected": "拒绝的数量",
        "final_remark": "最终备注",
        "value": "价值",
        "exchange_conversion": "兌換轉換",
        "contact_address": "聯絡地址",
        "cheque_fields": "支票字段",
        "cheque_details": "支票详情",
        "sales_return": "销售退货",
        "fulfilled_quantity": "已履行数量",
        "returned_quantity": "已退还数量",
        "parent_linked_document_no": "文件编号",
        "parent_linked_document_type": "文档类型",
        "swap_address": "交换地址",
        "vendor_address": "供应商地址",
        "job_work_out_order": "作业委外订单",
        "machine":"机器调度程序",
        "kind_of_packing": "包装编号和种类",
        "due_on": "到期日",
        "per": "每",
        "voucher_no": "凭证编号",
        "dated": "日期",
        "supplier_ref_order_no": "供应商参考/订单编号",
        "mode_term_of_payment": "支付方式/支付条件",
        "dispatch_doc_no": "发货文件编号",
        "dispatched_through": "发货方式",
        "duration_of_process": "加工时长",
        "nature_of_processing": "加工性质",
        "company_pan": "公司税号",
        "authorised_signatory": "授权签署人",
        "additional_charges": "附加费用",
        "contact_no": "联系方式",
        "supplier_inv_no": "供应商库存号",
        "basic_salary_wo_lop": "不减薪的基本工资",
        "cost": "成本",
        "stock_request": "库存请求",
        "target_warehouse_name": "目标仓库名称",
        "target_warehouse_code": "目标仓库代码",
        "selected_row": "选定的行",
        "selected_rack": "选定的货架",
        "selected_bin": "选定的箱",
        "requested_qty": "请求数量",
        "created_by": "创建者",
        "approved_date": "批准日期",
        "stock_issue": "库存发放",
        "gate_entry": "安检入口",
        "issued_quantity": "发放数量",
        "bom_product_table": "物料清单产品表",
        "list": "列表",
        "is_multi_product_table": "多产品表",
        "is_show_optional_group": "可选产品组",
        "tax_registration_number": "税务登记号",
        "tax_payer_id": "纳税人识别号",
        "wo_qty": "工单数量",
        "adhoc_items_message": "* 代表临时项目",
        "production_checklist": "生产清单",
        "product_description": "产品描述",
        "total_quantity": "总数量",
        "job_status": "作业状态",
        "qc_status": "质检状态",
        "show_job_status": "显示作业卡状态",
        "show_instruction": "显示说明",
        "optional": "可选",
        "document_title": "文档标题",
        "barcode_for_row_rack_bin": "行/货架/箱条形码",
        "show_tagged_bin_warehouse": "标记的箱式仓库",
        "hsn_sac_tax_table": "HSN/SAC 税表",
        "approval_section": "批准部分",
        "border": "边框",
        "spacing": "间距",
        "checked_by": "审核人",
        "authorized_by": "授权人",
        "word_spacing": "字间距",
        "page_count_settings": "页面计数设置",
        "single_digit": "单个数字",
        "page_with_numeric": "带数字的页面",
        "page_x_of_total_page": "第 x 页，共 y 页",
        "page_x_of_total_page_slash": "第 x 页 / 共 y 页",
        "service_requisition": "服务申请",
        "service_description": "服务说明",
        "sc_no":"SC 编号",
        "sc_date":"SC 日期",
        "sc_type":"SC型-",
        "delivery_order_doc_and_date": "交货单编号和日期",
        "e_way_bill_doc_and_date": "电子运单编号和日期",
        "party_order_doc_and_date": "订单编号和日期",
        "mode_of_transport": "运输方式",
        "bank_details": "银行详细信息",
        "bank_name": "银行名称",
        "company_seal": "公司印章",
        "regd_office": "注册办公室",
        "sub_total_in_words": "小计金额（文字）",
        "rounding_off_in_words": "取整金额（文字）",
        "outstanding_balance_in_words": "未付余额（文字）",
        "outstanding_balance_includes_current_in_words": "未付余额（包括当前）（文字）",
        "total_tax_amount_in_words": "税额总计（文字）",
        "before_tax_in_words": "税前金额（文字）",
        "additional_charges_in_words": "附加费用（文字）",
        "global_discount_in_words": "全局折扣（文字）",
        "cgst_in_words": "中央商品服务税（文字）",
        "igst_in_words": "综合商品服务税（文字）",
        "sgst_in_words": "州商品服务税（文字）",
        "ssgst_in_words": "次州商品服务税（文字）",
        "person_name": "姓名",
        "store_officer": "仓库管理员",
        "department_holder": "部门负责人",
        "purchase_department": "采购部",
        "issue_by": "发布者",
        "paper_orientation": "纸张方向",
        "landscape": "横向",
        "portrait": "纵向",
        "purchase_requisition": " 采购申请",
        "phone": " 电话",
        "requisition_qty": " 申请数量",
        "stock_qty": " 库存数量",
        "prev_purchase_rate": " 上一页购买率",
        "scheduled_date": " 约定的日期",
        "estimate_rate": " 预估费率",
        "estimate_value": " 估价",
        "cost_center": " 成本中心",
        "reserved": " 预订的",
        "division": " 分配",
        "department_name": " 部门名称",
        "indent_no": " 缩进编号",
        "indent_date": " 缩进日期",
        "indent_type": " 缩进类型",
        "project": " 项目",
        "authorised_date": " 授权日期",
        "requested_by": " 被要求",
        "for": " 为了",
        "authorised_by": " 授权人",
        "available_qty": "可用数量",
        "service_order": " 服务订单",
        "gst_no": " 商品及服务税编号",
        "gstin_no": " 商品及服务税编号 否",
        "pan_no": " PAN 号",
        "cin_no": " 犯罪登记号码",
        "cont_person": " 续。人",
        "order_date": " 订购日期",
        "shipment_address": " 发货地址",
        "prepared_date_time": "准备日期时间",
        "only": "仅有的",
        "completed": "完成",
        "location_detail": "位置详情",
        "transporter_detail": "运输详情",
        "item_detail": "物品详情",
        "material_receipt_note": "材料收货单",
        "mrn_no": "MRN 号",
        "authorized": "授权",
        "inspected_by": "通过检查",
        "store_manager": "店经理",
        "hod": "霍德",
        "account_manager": "客户经理",
        "po_bill_no_date": "采购订单编号/日期\n帐单编号/日期",
        "short_excess_rejected_qty": "短缺/多余/拒绝数量。",
        "accepted_qty": "接受数量",
        "basic_amt": "基本金额",
        "charges": "收费",
        "last_po": "最后订单",
        "cgst": "CGST",
        "sgst": "SGST",
        "igst": "IGST",
        "gst": "GST",
        "total_amount": "总金额",
        "total_passed_amount": "总通过量",
        "inspection_required": "需要检查",
        "qc_inspection": "质检检查",
        "work_order_no": "工单编号",
        "work_order_date": "工单日期",
        "work_order_due_date": "工单到期日期",
        "work_order_status": "工单状态",
        "edit_table_columns": "编辑表格列",
        "incoming": "进货",
        "final": "最终",
        "label": "标签",
        "both": "两者",
        "inspection_report": "检验报告",
        "inspection_date": "日期",
        "parts": "部件",
        "reworks": "返工",
        "final_inspection": "最终检验",
        "product_listing": "产品列表",
        "paper_margin": "纸张边距",
        "is_using_custom_margin": "使用自定义边距",
        "soa_customer": "账户报表",
        "soa_vendor": "账户报表 - 供应商",
        "company_reg_no": "公司注册号",
        "gst_reg_no": "增值税注册号",
        "from_date": "起始日期",
        "as_of_date": "截至日期",
        "to_date": "截至日期",
        "document_number": "文件编号",
        "particular": "具体内容",
        "amount_in_base": "基本金额",
        "balance_in_base": "基本余额",
        "ifsc_code": "IFSC 代码",
        "purchase_request_for_quotes": "询价请求",
        "purchase_inward_quotation": "采购内销报价",
        "rfq_no": "询价单号",
        "show_zero": "显示零",
        "show_total": "显示总计",
        "draft_watermark": "草稿水印",
        "draft": "草稿",
        "rotation": "旋转",
        "invoice_summary": "发票摘要",
        "bill_summary": "账单摘要",
        "amount_due": "应付金额",
        "total_pending_balance": "待付总余额",
        "serial_batch_custom_fields": "序列批次自定义字段",
        "supplier_part_name": "供应商零件名称",
        "supplier_part_number": "供应商零件编号",
        "supplier_part_description": "供应商零件描述",
        "deal_name": "交易名称",
        "deal_owner": "交易负责人",
        "aged_receivable": "应收账款",
        "document_due_date": "文档到期日期",
        "balance": "余额",
        "aged_receivable_1_30": "1到30天",
        "aged_receivable_31_60": "31到60天",
        "aged_receivable_61_90": "61到90天",
        "aged_receivable_90_plus": "超过90天", 
        "last_payment": "最后付款",
        "total_due": "总应付金额",
        "remit_to": "汇款至",
        "amount_enclosed": "随信附上金额",
        "statement_date": "报表日期",
        "account_no": "账号",
        "invoice_no": "发票号",
        "current_amount": "当前金额",
        "show_running_number": "显示流水号",
        "batch_quantity": "批量数量",
        "row": "行",
        "rack": "架子",
        "bin": "箱子",
        "hide_optional_product": "隐藏可选产品",
        "field_options": "字段选项",
        "show_custom_fields": "显示自定义字段",
        "payment_term": "付款条件",
        "source_row_rack_bin": "来源行/货架/箱",
        "destination_row_rack_bin": "目的地行/货架/箱",
    }
};

export default zh
